<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Close ticket</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <span class="font-size-base">Closing remarks are internal and will not be provided to the customer. If you’d like to add some further correspondence, please close this window and enter them in the ticket comments prior to closing the ticket.
              </span>
            </v-col>
            <v-col cols="12" md="12">
              Closing Remarks
              <ckeditor
                  :config="editorConfig"
                  v-model="support_ticket.closing_remarks"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.support_ticket.closing_remarks.$error">This information is required</span>
            </v-col>

          </v-row>
        </v-container>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            class="cancel-btn"
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            color="btn btn-primary"
            dark
            x-large
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import SupportTicketService from "@/services/support/support-ticket/SupportTicketService";

const supportTicket = new SupportTicketService();

export default {
  name: "CloseTicket",
  data() {
    return {
      edit: false,
      dialog: false,
      loading: false,
      support_ticket: {
        is_closed: 1,
        closing_remarks: ''
      },
      editorConfig: {
        width: 700,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']],
        versionCheck: false
      },
    }
  },
  validations: {
    support_ticket: {
      closing_remarks: {required}
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    editSupportTicket(item) {
      this.$v.$reset();
      this.dialog = true;
      this.edit = true;
      this.support_ticket = item;
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.update();
      }
    },
    update: function () {
      this.loading = true;
      supportTicket
          .closeTicket(this.support_ticket.id, this.support_ticket)
          .then(response => {
            this.$snotify.success("Ticket closed");
            this.resetForm();
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
            this.$tabs.close();
            this.$tabs.open('/support-ticket/all');
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          }).finally(() => this.loading = false);
    },
    resetForm() {
      this.$v.$reset();
      this.errors = [];
      this.support_ticket = {
        is_closed: 1,
        closing_remarks: ''
      }
    }
  },
}
</script>