import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;
export default class cartService{
    #api=null;
    constructor() {
        this.#api = API_URL + 'admin/cart';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    getPendingInvoice(data={},index = null){
        let url = `${this.#api}/pending-invoice`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    changeSchoolPay(data={},id){
        let url = `${this.#api}/${id}/change-school-pay`;
        return apiService.post(url, data);
    }
    findManualEnrolmentCartSummary(id){
        let url = `${this.#api}/${id}/find/manual-enrolment-cart/summary`;
        return apiService.get(url);
    }
    getPendingCart(data={},index=null){
        let url = `${this.#api}/pending-cart`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getSchoolPayInvoice(data={},index=null){
        let url = `${this.#api}/school-pay-invoice`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    updateCart(cartId,data={}){
        let url = `${this.#api}/${cartId}/update`;
        return apiService.post(url, data);
    }
    sendReminder(cartId){
        let url = `${this.#api}/${cartId}/send-reminder`;
        return apiService.post(url);
    }
    exportInvoiceToCsv(data){
        let url = `${this.#api}/invoice/export/csv?info=${data.info}&score_id=${data.score_id}&payment_status=${data.payment_status}`;
        window.open(url, "_blank")
    }

    exportInvoiceToExcel(data){
        let url = `${this.#api}/invoice/export/excel?info=${data.info}&score_id=${data.score_id}&payment_status=${data.payment_status}`;
        window.open(url, "_blank")
    }

}