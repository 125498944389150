<template>
  <v-app >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="m-5">
            <!--   Begin Support Ticket   -->
            <div>
              <div class="row">
                <div class="col-md-3">
                  <span
    class="
                          text-primary
                          font-weight-bolder font-size-lg
                          text-hover-primary
                        "
>
                        {{ support_ticket.name }}
                       </span> <br />
                  <span
                      class="
                           font-size-lg mb-5
                          text-hover-primary
                        "
                  > <b>Category:</b>
                        <span class="">
                          {{ support_ticket.category_name }}
                        </span> <br/>
                        <span class="font-size-md">
                          {{ formatted_date_time }}
                      </span>

                      </span>
                </div>
                <div class="col-md-3">
                   <span class="font-size-lg">
                        <b>Requested by: </b> <a class="text-primary" @click="goToUser">{{ support_ticket.user }}</a>
                      </span>
                  <br />
                  <span class="font-size-lg">
                        <b> SCORE: </b> {{ support_ticket.score_prefix }}
                      </span>
                </div>
                <div class="col-md-3">
                  <b>Assigned date:</b>
                  <span>
                        {{support_ticket.formatted_assigned_date ? support_ticket.formatted_assigned_date : "NA" }}
                      </span>
                  <br />
                  <b>Assigned to:</b>
                  <span>
                        {{support_ticket.assignedTo ? support_ticket.assignedTo : "NA" }}
                      </span>
                  <br />
                  <b>Assigned by:</b>
                  <span>
                        {{support_ticket.assignedBy ? support_ticket.assignedBy : "NA"}}
                      </span>
                </div>
                <div class="col-md-3 text-right">
                    <div v-if="!support_ticket.is_closed">
                      <!--                    <span class="my-3 mr-3" v-if="!support_ticket.is_read_admin">-->
                      <!--                      <v-btn-->
                      <!--                          rounded-->
                      <!--                          text-->
                      <!--                          dark-->
                      <!--                          class="btn btn-primary"-->
                      <!--                          color="white"-->
                      <!--                          @click="markAsRead(support_ticket)"-->
                      <!--                      >Mark as read</v-btn-->
                      <!--                      >-->
                      <!--                    </span>-->
                      <!--                    <span class="mr-3" v-if="canAssignTicket(support_ticket.candidate_product_type,support_ticket.type) && !support_ticket.is_closed">-->
                      <!--                      <v-btn-->
                      <!--                          rounded-->
                      <!--                          text-->
                      <!--                          dark-->
                      <!--                          class="btn btn-standard"-->

                      <!--                          @click="assignedTo"-->
                      <!--                      >Assign ticket</v-btn-->
                      <!--                      >-->
                      <!--                    </span>-->
                      <span class="badge badge-success mr-30" v-if="support_ticket.ticket_status == 'In progress'">
                        {{ support_ticket.ticket_status }}
                      </span>
                      <span class="badge badge-danger mr-30" v-else>
                        {{ support_ticket.ticket_status }}
                      </span>

                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <v-btn class="btn btn-primary mr-16" x-large dark>Options</v-btn>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" v-if="!support_ticket.is_read_admin && !support_ticket.is_closed">
                              <a class="navi-link"  @click="markAsRead(support_ticket)">
                                 <span class="navi-icon">
                                     <i class="far fa-eye"></i>
                                  </span>
                                <span class="navi-text">Mark as read</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item"  v-if="!support_ticket.is_closed  && !support_ticket.is_refunded && !support_ticket.is_rejected && support_ticket.is_approved && currentUser.access_type == 'federal'">
                              <a class="navi-link" @click="manageRefund">
                                 <span class="navi-icon">
                                     <i class="fas fa-money-bill"></i>
                                  </span>
                                <span class="navi-text">Process refund</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="!support_ticket.is_closed">
                              <a class="navi-link"  @click="closeTicket">
                                 <span class="navi-icon">
                                     <i class="fas fas fa-envelope"></i>
                                  </span>
                                <span class="navi-text">Close ticket</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="support_ticket.type=='refund' && !support_ticket.is_approved && !support_ticket.is_rejected">
                              <a class="navi-link" @click="approveRefund(support_ticket.id,support_ticket.is_refunded)">
                                 <span class="navi-icon">
                                     <i class="fas fas fa-user-edit"></i>
                                  </span>
                                <span class="navi-text">Approve/Not approve refund</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="canAssignTicket(support_ticket.candidate_product_type,support_ticket.type) && !support_ticket.is_closed">
                              <a class="navi-link" @click="assignedTo">
                                 <span class="navi-icon">
                                     <i class="fas fas fa-user-edit"></i>
                                  </span>
                                <span class="navi-text">Reassign ticket</span>
                              </a>
                            </b-dropdown-text>

                            <!--                          <b-dropdown-text tag="div" class="navi-item" v-if="canAssignTicket(support_ticket.candidate_product_type,support_ticket.type) && currentUser.access_type == 'federal' && !support_ticket.is_closed && support_ticket.assigned_type && support_ticket.assigned_by">-->
                            <!--                            <a class="navi-link" @click="unAssignedTicket">-->
                            <!--                                 <span class="navi-icon">-->
                            <!--                                     <i class="fas fas fa-user-edit"></i>-->
                            <!--                                  </span>-->
                            <!--                              <span class="navi-text">Unassign ticket</span>-->
                            <!--                            </a>-->
                            <!--                          </b-dropdown-text>-->

                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                      <!--                    <span v-if="!support_ticket.is_closed  && !support_ticket.is_refunded && !support_ticket.is_rejected && support_ticket.is_approved">-->
                      <!--                      <v-btn rounded text dark large-->
                      <!--                             class="my-2 mx-2 btn btn-info" @click="manageRefund">-->
                      <!--                        Refund-->
                      <!--                      </v-btn>-->
                      <!--                    </span>-->
                      <!--                    <span v-if="!support_ticket.is_closed">-->
                      <!--                      <v-btn rounded text dark-->
                      <!--                          class="mx-2 btn btn-standard"-->
                      <!--                          @click="closeTicket"-->
                      <!--                      >{{support_ticket.type=='refund' ? 'Cancel request' : 'Close ticket' }}</v-btn-->
                      <!--                      >-->
                      <!--                    </span>-->
                      <!--                    <span v-if="canAssignTicket(support_ticket.candidate_product_type,support_ticket.type) && currentUser.access_type == 'federal' && !support_ticket.is_closed && support_ticket.assigned_type && support_ticket.assigned_by">-->
                      <!--                      <v-btn rounded text dark class="mt-3 btn btn-standard"-->
                      <!--                          @click="unAssignedTicket">-->
                      <!--                        Unassign ticket-->
                      <!--                      </v-btn>-->
                      <!--                    </span>-->

                      <!--                    <span v-if="support_ticket.type=='refund' && !support_ticket.is_approved && !support_ticket.is_rejected">-->
                      <!--                      <v-btn rounded text dark class="my-2 mx-2 btn btn-primary"  color="white"-->
                      <!--                          @click="approveRefund(support_ticket.id,support_ticket.is_refunded)">Approve/ Not approve</v-btn>-->
                      <!--                    </span>-->
                    </div>
                </div>
              </div>
              <!-- <div class="d-flex align-items-start list-item card-spacer-x py-3" v-bind:class="{ 'ameb__bg-light-blue': !support_ticket.is_read_admin }" >
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center flex-wrap w-xxl-350px">
                    <div class="">

                    </div>
                  </div>

                  <div class="w-xxl-350px">
                    <div class="ml-5">

                    </div>
                  </div>

                  <div class="w-xxl-350px">
                    <div class="ml-5">

                    </div>
                  </div>
                  <div class="d-flex align-items-center flex-wrap w-xxl-550px" >

                  </div>
                </div>
              </div> -->

              <div class="mt-2 text-xs"  v-if="support_ticket.is_closed">
                <div class=" items-center text-sm">
                  <div class="rounded-md red lighten-5 p-4 mb-10">
                    <div class="flex">
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">
                          Ticket closed
                        </h3>
                        <div class="mt-2 text-sm text-red-700">
                          <p>This ticket was closed on {{support_ticket.formatted_closing_date}} with closing remarks: </p>
                        </div>
                        <div class="mt-2 text-sm text-black">
                          <span v-html="support_ticket.closing_remarks"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <v-row v-if="support_ticket.type=='refund'">

                <v-divider></v-divider>
                <v-col cols="12" md="12">
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-card class="border mt-4 bg-gray-100 lighten-3" elevation="0">
                        <v-card-title>
                          Enroller details
                        </v-card-title>
                        <v-card-text class="">
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Full name</div>
                            <div class="font-size-lg font-weight-medium">
                              {{ accountHolderDetail.full_name }}
                            </div>
                          </div>
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Email</div>
                            <div class="font-size-lg font-weight-medium">
                              {{ accountHolderDetail.email }}
                            </div>
                          </div>
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Role</div>
                            <div class="font-size-lg font-weight-medium">
                              {{accountHolderDetail.role_text}}
                            </div>
                          </div>
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Status</div>
                            <div class="font-size-lg font-weight-medium" v-html="accountHolderDetail.status_text">

                            </div>
                          </div>

                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="4"  v-if="candidateDetail">
                      <v-card class="border mt-4 bg-gray-100 lighten-3" elevation="0">
                        <v-card-title>
                          Candidate details
                        </v-card-title>
                        <v-card-text class="">
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Full name</div>
                            <div class="font-size-lg font-weight-medium">
                              {{candidateDetail.full_name}}
                            </div>
                          </div>
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Enrolment key</div>
                            <div class="font-size-lg font-weight-medium">
                              <a href="#" @click="getCandidateSummary(candidateDetail.exam_key)" class="text-primary font-weight-bolder text-hover-primary mb-1"><u>{{candidateDetail.exam_key}} </u>
                              </a>
                            </div>
                          </div>
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Enrolment pin</div>
                            <div class="font-size-lg font-weight-medium">
                              {{candidateDetail.exam_pin}}
                            </div>
                          </div>
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Date of birth</div>
                            <div class="font-size-lg font-weight-medium">
                              {{candidateDetail.dob }}
                            </div>
                          </div>
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Enrolment status</div>
                            <div class="font-size-lg font-weight-medium" v-html="candidateDetail.enrolment_status_badge">
                            </div>
                          </div>
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Exam status</div>
                            <div class="font-size-lg font-weight-medium" v-html="candidateDetail.exam_status_badge">
                            </div>
                          </div>
                          <div class="d-flex mb-3 justify-content-between" v-if="candidateDetail && candidateDetail.exam_session_name">
                            <div class="font-size-lg font-weight-bold">Session</div>
                            <div class="font-size-lg font-weight-medium" v-html="candidateDetail.exam_session_name">
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-card class="border mt-4 bg-gray-100 lighten-3" elevation="0">
                        <v-card-title>
                          Transaction information
                        </v-card-title>
                        <v-card-text class="">
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Product name</div>
                            <div class="font-size-lg font-weight-medium">
                              {{cart_item.product_name}}
                            </div>
                          </div> <div class="d-flex mb-3 justify-content-between">
                          <div class="font-size-lg font-weight-bold">Transaction amount</div>
                          <div class="font-size-lg font-weight-medium">
                            {{ scoreDetail.currency_symbol}}{{cart_item.amount}}
                          </div>
                        </div>
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Transaction Id</div>
                            <div class="font-size-lg font-weight-medium">
                              {{ cart.transaction_id }}
                            </div>
                          </div>
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Payment status</div>
                            <div class="font-size-lg font-weight-medium" v-html="cart.payment_status_badge">

                            </div>
                          </div>
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Payment method</div>
                            <div class="font-size-lg font-weight-medium">
                              {{cart.payment_method_text}}
                            </div>
                          </div>
                          <div class="d-flex mb-3 justify-content-between" v-if="cart.payment_method_text == 'Other' && cart.received_by_type">  
                            <div class="font-size-lg font-weight-bold">Received by type</div>
                            <div class="font-size-lg font-weight-medium">
                              {{cart.received_by_type_text}}
                            </div>
                          </div>
                          <div class="d-flex mb-3 justify-content-between" v-if="cart.payment_method_text == 'Other' && cart.received_by">
                            <div class="font-size-lg font-weight-bold">Received by</div>
                            <div class="font-size-lg font-weight-medium">
                              {{cart.received_by_full_name}}
                            </div>
                          </div>
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Transaction date</div>
                            <div class="font-size-lg font-weight-medium">
                              {{cart.order_paid_date_format}}
                            </div>
                          </div>
                          

                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>

              </v-row>

              <v-row class="mb-3" v-if="support_ticket.type!='refund'">
                <v-divider></v-divider>
                <v-col cols="12" md="12">
                  <v-row>
                    <v-col cols="12" md="4"  v-if="candidateDetail">
                      <v-card class="border mt-4 bg-gray-100 lighten-3" elevation="0">
                        <v-card-title>
                          Candidate details
                        </v-card-title>
                        <v-card-text class="">
                          <div class="d-flex mb-2 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Full name</div>
                            <div class="font-size-lg font-weight-medium">
                              {{candidateDetail.full_name}}
                            </div>
                          </div>
                          <div class="d-flex mb-2 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Enrolment key</div>
                            <div class="font-size-lg font-weight-medium">
                              <a href="#" @click="getCandidateSummary(candidateDetail.exam_key)" class="text-primary font-weight-bolder text-hover-primary mb-1"><u>{{candidateDetail.exam_key}} </u>
                              </a>
                            </div>
                          </div>
                          <div class="d-flex mb-2 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Enrolment pin</div>
                            <div class="font-size-lg font-weight-medium">
                              {{candidateDetail.exam_pin}}
                            </div>
                          </div>
                          <div class="d-flex mb-2 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Date of birth</div>
                            <div class="font-size-lg font-weight-medium">
                              {{candidateDetail.dob }}
                            </div>
                          </div>
                          <div class="d-flex mb-3 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Enrolment status</div>
                            <div class="font-size-lg font-weight-medium" v-html="candidateDetail.enrolment_status_badge">
                            </div>
                          </div>
                          <div class="d-flex mb-2 justify-content-between">
                            <div class="font-size-lg font-weight-bold">Exam status</div>
                            <div class="font-size-lg font-weight-medium" v-html="candidateDetail.exam_status_badge">
                            </div>
                          </div>
                          <div class="d-flex mb-2 justify-content-between" v-if="candidateDetail && candidateDetail.exam_session_name">
                            <div class="font-size-lg font-weight-bold">Session</div>
                            <div class="font-size-lg font-weight-medium" v-html="candidateDetail.exam_session_name">
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <div class="mt-5 mb-5 rounded-md p-4 mb-10" v-if="support_ticket.type=='refund' && support_ticket.is_approved=='1'" style="background: #c1f6c1">
                <h4>Approved for refund</h4>
                <v-row >
                  <v-col cols="12">
                    <p class="font-size-lg"><b>Approved date:</b> {{support_ticket.approved_date_format}}</p>
                    <p class="font-size-lg"><b>Approved by:</b> {{support_ticket.approvedBy}}</p>
                    <p class="font-size-lg"><b>Approved remarks:</b> {{support_ticket.approved_remarks}} </p>
                  </v-col>
                </v-row>
              </div>
              <div class="mt-5 mb-5 rounded-md p-4 mb-10" v-if="support_ticket.type=='refund' && support_ticket.is_rejected=='1'" style="background: #f6b6b6">
                <h4>Not approved for refund</h4>
                <v-row >
                  <v-col cols="12">
                    <p class="font-size-lg"><b>Not approved date:</b> {{support_ticket.rejected_date_format}}</p>
                    <p class="font-size-lg"><b>Not approved by:</b> {{support_ticket.rejectedBy}}</p>
                    <p class="font-size-lg"><b>Not approved remarks:</b> {{support_ticket.rejected_remarks}} </p>
                  </v-col>
                </v-row>
              </div>

              <div class="mt-2 w-full" v-if="support_ticket.description!=''">
                <div class=" items-center text-sm">
                  <div class="rounded-md bg-gray-100 p-4 mb-10">
                    <div class="flex">
                      <div class="ml-3">
                        <h3 class="text-sm font-size-md text-gray-800">
                          Original request
                        </h3>
                        <div class="mt-2 text-sm text-gray-700">
                          <p v-html="support_ticket.description"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <v-row
                  class="ml-2 mb-2"
                  v-if="support_ticket && support_ticket.attachments != null"
              >
                <v-col
                    v-for="(attachment, index) in support_ticket.attachments"
                    :key="index"
                    class="d-flex child-flex"
                    cols="1"
                >
                  <a
                      v-if="attachment.file_path && attachment.file_type == 'image'"
                      :href="attachment.file_path.original"
                      target="_blank"
                  >
                    <v-img
                        :src="attachment.file_path.original"
                        :lazy-src="attachment.file_path.original"
                        aspect-ratio="1"
                        max-width="100"
                        max-height="100"
                        class="grey lighten-2"
                    >
                    </v-img>
                  </a>
                  <a
                      v-if="attachment.file_path && attachment.file_type == 'pdf'"
                      :href="attachment.file_path.original"
                      target="_blank"
                  >
                    <v-img
                        src="/media/image/pdf.png"
                        lazy-src="/media/image/pdf.png"
                        aspect-ratio="1"
                        max-width="100"
                        max-height="100"
                        class="grey lighten-2"
                    >
                    </v-img>
                  </a>
                  <a
                      v-if="attachment.file_path && attachment.file_type == 'doc'"
                      :href="attachment.file_path.original"
                      target="_blank"
                  >
                    <v-img
                        src="/media/image/word.png"
                        lazy-src="/media/image/word.png"
                        aspect-ratio="1"
                        max-width="100"
                        max-height="100"
                        class="grey lighten-2"
                    >
                    </v-img>
                  </a>
                  <a
                      v-if="attachment.file_path && attachment.file_type == 'xls'"
                      :href="attachment.file_path.original"
                      target="_blank"
                  >
                    <v-img
                        src="/media/image/xlsx.png"
                        lazy-src="/media/image/xlsx.png"
                        aspect-ratio="1"
                        max-width="100"
                        max-height="100"
                        class="grey lighten-2"
                    >
                    </v-img>
                  </a>
                  <a
                      v-if="attachment.file_path && attachment.file_type == 'cvs'"
                      :href="attachment.file_path.original"
                      target="_blank"
                  >
                    <v-img
                        src="/media/image/cvs.png"
                        lazy-src="/media/image/cvs.png"
                        aspect-ratio="1"
                        max-width="100"
                        max-height="100"
                        class="grey lighten-2"
                    >
                    </v-img>
                  </a>
                  <a
                      v-if="attachment.attachment_url"
                      :href="attachment.attachment_url"
                      target="_blank"
                  >
                    Attachment Link
                  </a>
                </v-col>
              </v-row>
            </div>
            <!--   End Support Ticket   -->
            <v-divider></v-divider>

            <!--   Begin Support Ticket Threads   -->
            <div v-bind:class="thread.admin_user ? 'my-2 pb-3 bg-yellow-100 rounded' : 'my-2 pb-3 blue lighten-5 rounded'"
                v-for="(thread, index) in support_ticket_threads"
                :key="index"
            >
              <div
                  class="d-flex align-items-start list-item card-spacer-x py-3"
              >
                <div class="d-flex align-items-center">
<!--                  <div class="d-flex align-items-center flex-wrap w-xxl-50px">-->
<!--                    <div class="symbol symbol-35px me-3">-->
<!--                      <div class="symbol-label bg-light-danger">-->
<!--                              <span class="text-danger">{{-->
<!--                                  thread.admin_user_symbol_label-->
<!--                                      ? thread.admin_user_symbol_label-->
<!--                                      : thread.user_symbol_label-->
<!--                                }}</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div
                      class="
                            d-flex
                            align-items-center
                            flex-wrap
                            w-xxl-500px

                          "
                  >
                          <span class="font-size-sm text-black">
                            {{
                              thread.admin_user ? thread.admin_user : thread.user
                            }}
                            <span
                                v-if="thread.admin_user"
                                class="badge badge-primary mt-2"
                            >{{ thread.admin_user }} (Admin)</span
                            >
                            <span v-else class="badge badge-primary mt-2"
                            >Enroller</span
                            >
                          </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <span class="float-right badge badge-warning" v-if="thread.is_admin_note">
                    Admin note only
                  </span>
                  <v-btn class="float-right" small rounded color="red" dark @click="deleteDraft(thread)" v-if="thread.is_draft">
                    Delete draft
                  </v-btn>
                  <v-btn class="mx-3 float-right"  small rounded color="primary" dark @click="editDraft(thread)" v-if="thread.is_draft">
                    Edit draft
                  </v-btn>
                  <span class="mx-3 float-right">
                    {{ thread.formatted_created_at }}
                  </span>
                </div>
              </div>
              <div v-html="thread.description" class="mt-4 ml-7"></div>
              <div class="m-5 d-flex" v-if="thread.thread_attachments.length > 0">
                      <span
                          v-for="(attachment, index) in thread.thread_attachments"
                          :key="index"
                      >
                          <a v-if="attachment.file_path && attachment.file_type == 'image'" :href="attachment.file_path.original" class="text-primary font-weight-bolder mb-1 mx-2 font-size-lg" target="_blank" >
                                 <v-img
                                     :lazy-src="attachment.file_path.original"
                                     :src="attachment.file_path.original"
                                     max-height="50"
                                     max-width="50"
                                 >
                                 </v-img>
                          </a>
                          <a v-if="attachment.file_path && attachment.file_type == 'pdf'" :href="attachment.file_path.original" class="text-primary font-weight-bolder mb-1 mx-2 font-size-lg" target="_blank" >
                                 <v-img
                                     lazy-src="/media/image/pdf.png"
                                     src="/media/image/pdf.png"
                                     max-height="50"
                                     max-width="50"
                                 >
                                 </v-img> Attachment {{index + 1}}
                          </a>
                          <a v-if="attachment.file_path && attachment.file_type == 'doc'" :href="attachment.file_path.original" class="text-primary font-weight-bolder mb-1 mx-2 font-size-lg" target="_blank" >
                                <v-img
                                    lazy-src="/media/image/word.png"
                                    src="/media/image/word.png"
                                    max-height="50"
                                    max-width="50"
                                >
                               </v-img> Attachment {{index + 1}}
                          </a>
                          <a v-if="attachment.file_path && attachment.file_type == 'xls'" :href="attachment.file_path.original" class="text-primary font-weight-bolder mb-1 mx-2 font-size-lg" target="_blank" >
                                 <v-img
                                     lazy-src="/media/image/xlsx.png"
                                     src="/media/image/xlsx.png"
                                     max-height="50"
                                     max-width="50"
                                 >
                                 </v-img> Attachment {{index + 1}}
                          </a>
                          <a v-if="attachment.file_path && attachment.file_type == 'cvs'" :href="attachment.file_path.original" class="text-primary font-weight-bolder mb-1 mx-2 font-size-lg" target="_blank" >
                                 <v-img
                                     lazy-src="/media/image/cvs.png"
                                     src="/media/image/cvs.png"
                                     max-height="50"
                                     max-width="50"
                                 >
                                 </v-img> Attachment {{index + 1}}
                          </a>
                          <a
                              v-if="attachment.attachment_url"
                              :href="attachment.attachment_url"
                              class="
                              text-primary
                              font-weight-bolder
                              mb-1
                              mx-2
                              font-size-lg
                            "
                              target="_blank"
                          >
                            Attachment Link
                          </a>
                      </span>
              </div>
            </div>
            <!--   End Support Ticket Threads   -->
            <div class="mt-5 mb-5 rounded-md p-4 mb-10" v-if="ticket_activity_logs.length > 0" style="background: #f6b581">
              <h4>Assigned activity</h4>
              <v-row >
                <v-col cols="12" v-for="(ticket, index) in ticket_activity_logs" :key="index">
                  <div class="d-flex">
                    <p class="my-3 mr-3 font-size-lg"><b>Assigned to:</b> {{ticket.assignedTo}}</p>
                    <p class="my-3 mr-3 font-size-lg"><b>Assigned by:</b> {{ticket.assignedBy}}</p>
                    <p class="my-3 mr-3 font-size-lg"><b>Assigned date:</b> {{ticket.assigned_date}}</p>
                    <p class="my-3 mr-3 font-size-lg"><b>Assigned type:</b> {{ticket.assigned_type_text}}</p>
                  </div>
                </v-col>
              </v-row>
            </div>



              <v-divider v-if="support_ticket.type=='refund'"></v-divider>
              <div class=" mb-5 " v-if="!support_ticket.is_closed">
                <v-row>
                <v-col cols="12">
                  <p>Enter your comments and then select save as internal note or to send to the customer. Internal comments will be visible to any SCORE admin who has access to this ticket.</p>
                  <ckeditor
                      :config="editorConfig"
                      v-model="ticket_thread.description"
                  >
                  </ckeditor>
                  <span
                      class="text-danger"
                      v-if="$v.ticket_thread.description.$error"
                  >This information is required</span
                  >
                </v-col>
<!--                <v-col cols="12" md="4">-->
<!--                  <div class="text-left">-->
<!--                    <v-select-->
<!--                        label="Attachment type"-->
<!--                        v-model="ticket_thread.attachment_type"-->
<!--                        :items="types"-->
<!--                        item-text="name"-->
<!--                        item-value="value"-->
<!--                        clearable-->
<!--                        outlined-->
<!--                        dense-->
<!--                    >-->
<!--                    </v-select>-->
<!--                  </div>-->
<!--                </v-col>-->
                <v-col cols="12" md="6">
                  <div>
                    <v-file-input
                        label="Attachment files"
                        v-model="ticket_thread.files"
                        prepend-icon=""
                        prepend-inner-icon="mdi-file"
                        multiple
                        clearable
                        counter
                        :show-size="1000"
                        outlined
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                            v-if="index < 4"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                            v-else-if="index === 4"
                            class="text-overline grey--text text--darken-3 mx-2"
                        >
                                                +{{ ticket_thread.files.length - 4 }} file(s)
                                            </span>
                      </template>
                    </v-file-input>
<!--                    <v-text-field-->
<!--                        v-if="ticket_thread.attachment_type == 'url'"-->
<!--                        label="Attachment Url"-->
<!--                        v-model="ticket_thread.attachment_url"-->
<!--                        :error="$v.ticket_thread.attachment_url.$error"-->
<!--                        outlined-->
<!--                        dense-->
<!--                    >-->
<!--                    </v-text-field>-->
<!--                    <span-->
<!--                        class="text-danger"-->
<!--                        v-if="$v.ticket_thread.attachment_url.$error"-->
<!--                    >This information is required</span-->
<!--                    >-->
                  </div>
                </v-col>
              </v-row>
                <v-row v-if="ticket_thread.thread_attachments && ticket_thread.thread_attachments.length > 0">
                <v-col
                    v-for="(attachment, index) of ticket_thread.thread_attachments"
                    :key="index"
                    cols="12"
                    md="1"
                >
                  <v-card style="position: relative" max-width="100">
                    <a
                        v-if="attachment.file_path && attachment.file_type == 'image'"
                        :href="attachment.file_path.original"
                        target="_blank"
                    >
                      <v-img
                          :src="attachment.file_path.original"
                          :lazy-src="attachment.file_path.original"
                          aspect-ratio="1"
                          max-width="100"
                          max-height="100"
                          class="grey lighten-2"
                      ></v-img>
                    </a>
                    <a
                        v-if="attachment.file_path && attachment.file_type == 'pdf'"
                        :href="attachment.file_path.original"
                        target="_blank"
                    >
                      <v-img
                          src="/media/image/pdf.png"
                          lazy-src="/media/image/pdf.png"
                          aspect-ratio="1"
                          max-width="100"
                          max-height="100"
                          class="grey lighten-2"
                      >
                      </v-img>
                    </a>
                    <a
                        v-if="attachment.file_path && attachment.file_type == 'doc'"
                        :href="attachment.file_path.original"
                        target="_blank"
                    >
                        <v-img
                            src="/media/image/word.png"
                            lazy-src="/media/image/word.png"
                            aspect-ratio="1"
                            max-width="100"
                            max-height="100"
                            class="grey lighten-2"
                        ></v-img>
                    </a>
                    <a
                        v-if="attachment.file_path && attachment.file_type == 'xls'"
                        :href="attachment.file_path.original"
                        target="_blank"
                    >
                        <v-img
                            src="/media/image/xlsx.png"
                            lazy-src="/media/image/xlsx.png"
                            aspect-ratio="1"
                            max-width="100"
                            max-height="100"
                            class="grey lighten-2"
                        ></v-img>
                    </a>
                    <a
                        v-if="attachment.file_path && attachment.file_type == 'cvs'"
                        :href="attachment.file_path.original"
                        target="_blank"
                    >
                        <v-img
                            src="/media/image/cvs.png"
                            lazy-src="/media/image/cvs.png"
                            aspect-ratio="1"
                            max-width="100"
                            max-height="100"
                            class="grey lighten-2"
                        ></v-img>
                    </a>
                    <v-btn
                        class="deleteBtn"
                        @click="deleteConfirm(attachment.id)"
                        absolute
                        rounded
                        right
                        top
                        x-small
                    >
                      <v-icon small color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
                <v-row>
                <v-col cols="12" md="6">
                  <v-btn
                      @click="saveAsDraft"
                      :loading="isLoading"
                      class="btn btn-primary text-white"
                  >Save as draft</v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="text-right">
                    <v-btn
                        @click="createThreads(true)"
                        :loading="loading"
                        text
                        x-large
                    >Save as internal note</v-btn>
                    <v-btn
                        @click="createThreads(false)"
                        :loading="loading"
                        class="btn btn-primary text-white"
                        x-large
                    >Save and send to customer</v-btn>
                  </div>
                </v-col>

              </v-row>
              </div>
              <close-ticket ref="close-ticket"></close-ticket>
          </div>
          </div>
        </div>
        <assigned-to ref="assigned-to" @refresh="getSupportTicket"></assigned-to>
        <manage-refund ref="manage-refund" @refresh="getSupportTicket"></manage-refund>
        <approved-refund ref="refund" @refresh="getSupportTicket"></approved-refund>
      </div>
    </div>
  </v-app>
</template>
<script>
import SupportTicketService from "@/services/support/support-ticket/SupportTicketService";
import SupportTicketThreadService from "@/services/support/support-ticket-thread/SupportTicketThreadService";
import CloseTicket from "@/view/pages/view/support/support-ticket/close-ticket/CloseTicket";
import { required, requiredIf } from "vuelidate/lib/validators";
import SupportTicketActivityLogService from "@/services/support/support-ticket-activity-log/SupportTicketActivityLogService";
import AssignedTo from "@/view/pages/view/support/support-ticket/assigned/AssignedTo";
import SuppoertTicketAttachmentService from "@/services/support/support-ticket-attachment/SuppoertTicketAttachmentService";
import ManageRefund from "./refund/ManageRefund";
import CandidateService from "@/services/candidate/CandidateService";
import AccountHolderService from "@/services/user/accountholder/AccountholderService";
import CartService from "@/services/cart/cartService";
import CartItemService from "@/services/cart/cartItem/CartItemService";
import ScoreService from "@/services/score/score/ScoreService";
import ProductService from "@/services/product/ProductService";
import ApprovedRefund from "@/view/pages/view/support/support-ticket/refund/ApprovedRefund";

const product=new ProductService();
const score=new ScoreService();
const cartItem=new CartItemService();
const cart=new CartService();
const accountHolder=new AccountHolderService();
const candidate=new CandidateService();
const supportTicketThread = new SupportTicketThreadService();
const supportTicket = new SupportTicketService();
const ticketActivityLog = new SupportTicketActivityLogService();
const attachmentService = new SuppoertTicketAttachmentService();
export default {
  components: {AssignedTo, CloseTicket,ManageRefund,ApprovedRefund },

  data() {
    return {
      total: null,
      perPage: null,
      page: null,
      loading: false,
      isLoading: false,
      supportTicketId: "",
      support_ticket: {},
      currentUser: {},
      ticket_activity_logs: [],
      support_ticket_threads: [],
      ticket_thread: {
        ticket_id: "",
        ticket_thread_id: "",
        is_admin_note: false,
        is_draft: false,
        is_read_user: true,
        description: "",
        attachment_type: "",
        files: [],
        attachment_url: "",
      },
      types: [
        { name: "File", value: "file" },
        { name: "Url", value: "url" },
      ],
      editorConfig: {
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
        versionCheck: false
      },
      candidateDetail:{},
      accountHolderDetail:{},
      cart:{},
      cart_item:{},
      scoreId:'',
      scoreDetail:{},
    };
  },
  validations: {
    ticket_thread: {
      description: { required }
    },
  },
  mounted() {
    this.supportTicketId = this.$route.params.supportTicketId;
    this.getSupportTicket();
    this.getSupportTicketThreads();
    this.getAllAssignedTicket();
    this.getCurrentUser();

  },
  computed: {

    formatted_date_time() {
      if (this.support_ticket && this.support_ticket.formatted_created_at) {
        let date = this.support_ticket.formatted_created_at.split(" ");
        return (
          date[0] +
          " " +
          date[1] +
          " " +
          date[2] +
          "," +
          date[3] +
          " " +
          date[4]
        );
      }
      return null;
    },
    no_of_files() {
      if (this.ticket_thread.files != null) {
        return this.ticket_thread.files.length;
      }
      return "0";
    },
    routeTab(){
      if(this.support_ticket){
        return {
          title: `${this.support_ticket.user} - ${this.support_ticket.name}`
        }
      }
    }
  },
  methods: {
    getCandidateSummary(examKey){
      this.$router.push({
        name: 'candidate-summary',
        params: {examKey: examKey}
      })
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    getCandidateDetail(){
      candidate
          .show(this.support_ticket.candidate_id)
          .then((response) => {
            this.candidateDetail=response.data.candidate;

          })
          .catch((err) => {

          }).finally(() => {

      });
    },
    getAccountHolderDetail(){
      accountHolder
          .show(this.support_ticket.user_id)
          .then((response) => {
            this.accountHolderDetail=response.data.accountHolder;
            if(this.accountHolderDetail){
              this.scoreId=this.accountHolderDetail.current_score_id;
              this.findScoreDetail();
            }
          })
          .catch(err => {

          }).finally(() => {

      });
    },
    getCartDetail() {
      cart
          .show(this.support_ticket.cart_id)
          .then((response) => {
           this.cart=response.data.cart;
          })
          .catch(err => {

          }).finally(() => {

      });
    },
    findCartItemDetail(item){
      let data={
        'candidate_id':this.support_ticket.candidate_id
      };
      cartItem
          .getItemByCandidate(this.support_ticket.cart_id,data)
          .then(response =>{
            this.cart_item=response.data.cart_item;


          })
    },
    //Score
    findScoreDetail(){
      if(this.scoreId){
        score
            .show(this.scoreId)
            .then((response) => {
              this.scoreDetail=response.data.score;
            })
            .catch(err => {

            }).finally(() => {

          });
      }
    },
    markAsRead(ticket) {
      if(!ticket.is_read_admin) {
        ticket.is_read_admin = true;
        supportTicket.update(ticket.id, ticket).then(response =>{
          this.getSupportTicket();
        }).catch(() => {
          this.$snotify.error("Oops something went wrong");
        });
      }
    },
    goToUser() {
      if(this.support_ticket.user_type == 'account_holder') {
        this.$router.push({
          name: 'accountholder-edit',
          params: {accountholderId: this.support_ticket.user_id}
        });
      } else if(this.support_ticket.user_type == 'examiner') {
        this.$router.push({
          name: 'examiner-edit',
          params: {examinerId: this.support_ticket.user_id}
        });
      } else if(this.support_ticket.user_type == 'supervisor') {
        this.$router.push({
          name: 'supervisor-edit',
          params: {supervisorId: this.support_ticket.user_id}
        });
      } else {
        this.$router.push({
          name: 'online-marker-edit',
          params: {onlineMarkerId: this.support_ticket.user_id}
        });
      }
    },
    closeTicket() {
      this.$refs["close-ticket"].editSupportTicket(this.support_ticket);
    },
    convertToFormData() {
      let formData = new FormData();
      this.ticket_thread.ticket_id = this.supportTicketId;
      if (this.ticket_thread.files && this.ticket_thread.files.length > 1) {
        for (const file in this.ticket_thread.files) {
          if (
            this.ticket_thread.files[file] != null &&
            this.ticket_thread.files[file] !== undefined
          ) {
            formData.append(`files[${file}]`, this.ticket_thread.files[file]);
          }
        }
      }
      if (this.ticket_thread.files.length == 1) {
        formData.append("files", this.ticket_thread.files[0]);
      }
      for (let key in this.ticket_thread) {
        if (key != "files" && this.ticket_thread[key] && key != "thread_attachments") {
          formData.append(key, this.ticket_thread[key]);
        }
      }
      return formData;
    },

    createThreads(adminOnly) {
      if(adminOnly){
        this.ticket_thread.is_admin_note = true
      }
      else{
        this.ticket_thread.is_admin_note = false
      }
      if(this.ticket_thread.id) {
        this.ticket_thread.is_draft = false;
        this.update();
      }else {
        this._create();
      }
    },
    saveAsDraft() {
      if(this.ticket_thread.id) {
        this.update();
      }else {
        this.ticket_thread.is_draft = true;
        this._create();
      }
    },
    editDraft(item) {
      this.ticket_thread = Object.assign({}, this.ticket_thread, item);
    },
    deleteDraft(item) {
      this.$confirm({
        message: `Are you sure you want delete Draft Thread ?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            supportTicketThread.delete(item.id).then(response => {
              this.$snotify.success("Ticket reply deleted");
              this.getSupportTicketThreads();
            }).catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
          }
        },
      });
    },
    _create: function () {
      let formData = this.convertToFormData();
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if(this.ticket_thread.is_draft) {
          this.isLoading = true;
        } else {
          this.loading = true;
        }
        supportTicketThread
          .create(formData)
          .then((response) => {
            if(!this.ticket_thread.is_draft) {
              this.markAsRead(this.support_ticket);
              this.$snotify.success("Ticket reply added");
              this.loading = false;
            } else {
              this.$snotify.success("Ticket reply saved as draft");
              this.isLoading = false;
            }
            this.getSupportTicketThreads();
            this.resetForm();
          })
          .catch((err) => {
            this.loading = false;
            this.isLoading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
      }
    },
    update: function () {
      let formData = this.convertToFormData();
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if(this.ticket_thread.is_draft) {
          this.isLoading = true;
        } else {
          this.loading = true;
        }
        supportTicketThread
          .update(this.ticket_thread.id, formData)
          .then((response) => {
            this.loading = false;
            this.isLoading = false;
            if(!this.ticket_thread.is_draft) {
              this.$snotify.success("Ticket replied");
              this.markAsRead(this.support_ticket);
            } else {
              this.$snotify.success("Ticket reply saved as draft");
            }
            this.getSupportTicketThreads();
            this.resetForm();
          })
          .catch((err) => {
            this.loading = false;
            this.isLoading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
      }
    },
    getSupportTicket() {
      supportTicket
        .show(this.supportTicketId)
        .then((response) => {
            if(response.data.status == 'UnAuthorized') {
              this.$router.push({
                name: '404'
              });
            }else{
              this.support_ticket = response.data.supportTicket;
              if(this.support_ticket.type=='refund'){
                if(this.support_ticket.user_id){
                  this.getAccountHolderDetail();
                }
                if(this.support_ticket.cart_id) {
                  this.getCartDetail();
                  this.findCartItemDetail();
                }
              }
              if(this.support_ticket.candidate_id) {
                this.getCandidateDetail();
              }
            }
        })
        .catch((err) => {})
        .finally(() => (this.loading = false));
    },
    getAllAssignedTicket() {
      ticketActivityLog
        .getAllAssignedTicket(this.supportTicketId)
        .then((response) => {
            if(response.data.status == 'UnAuthorized') {
              this.$router.push({
                name: '404'
              });
            }else{
              this.ticket_activity_logs = response.data.data;
            }
        })
        .catch((err) => {})
        .finally(() => (this.loading = false));
    },
    getSupportTicketThreads() {
      supportTicketThread
        .getPaginateByTicket(this.supportTicketId)
        .then((response) => {
          this.support_ticket_threads = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {})
        .finally(() => (this.loading = false));
    },
    resetForm() {
      this.$v.$reset();
      this.ticket_thread = {
        ticket_id: "",
        ticket_thread_id: "",
        is_admin_note: false,
        description: "",
        attachment_type: "",
        files: [],
        attachment_url: "",
      };
    },

    assignedTo() {
      this.$refs['assigned-to'].assignedTo(this.support_ticket)
    },

    unAssignedTicket() {
      this.$confirm({
        message: `Are you sure ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            supportTicket.unassignedTicket(this.supportTicketId).then(response => {
              this.$snotify.success("Ticket Has Been UnAssigned");
              this.getSupportTicket();
            }).catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
          }
        },
      });
    },

    deleteConfirm(id) {
      this.$confirm({
        message: `Are you sure ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            attachmentService.delete(id).then(response => {
              this.$snotify.success("Attachment has been remove");
              this.getSupportTicketThreads();
              this.resetForm();
            }).catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
          }
        },
      });
    },
    manageRefund() {
      this.$refs['manage-refund'].manageRefund(this.support_ticket)
    },
    approveRefund(ticketId,refundStatus){
      this.$refs['refund'].openDialog(ticketId,refundStatus);
    },
    canAssignTicket(productType=null,type=null){
      if(type=='refund'){
        if(productType=='online_course'){
          return false;
        }else if(productType=='practical_exam'){
          return true;
        }else if(productType=='online_exam'){
          return false;
        }
      }else{
        return true;
      }
    }
  },
};
</script>

<style lang="scss">
.v-expansion-panels {
  z-index: unset !important;
}
.v-expansion-panel-header__icon {
  display: none !important;
}
.deleteBtn {
  min-width: 24px !important;
  padding: 0 !important;
  top: 2px !important;
  right: 2px !important;
}
</style>