import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js';
const apiService = Apiservice;

export default class CartItemService
{
    #api=null;
    constructor() {
        this.#api = API_URL + 'admin/cart-item';
    }
    getItemByCart(cartId){
        let url = `${this.#api}/${cartId}/get-by-cart`;
        return apiService.query(url);
    }
    getFinancialItemByCart(cartId){
        let url = `${this.#api}/${cartId}/get-financial-by-cart`;
        return apiService.query(url);
    }
    getItemByCandidate(cartId,data={})
    {
        let url = `${this.#api}/${cartId}/get-by-candidate`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getAllCandidateCartItem(candidateId){

        let url = `${this.#api}/${candidateId}/get/all/candidate`;

        return apiService.get(url);
    }
}