import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class SuppoertTicketAttachmentService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/support-ticket-attachment';
    }

    delete(attachmentId) {
        let url = `${this.#api}/${attachmentId}`
        return apiService.post(url)
    }
}