import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class SupportTicketActivityLogService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/support-ticket-activity-log';
    }

    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    getAllAssignedTicket(ticketId) {
        let url = `${this.#api}/${ticketId}`;
        return apiService.get(url);
    }
}