<template>
  <v-dialog v-model="dialog"
               max-width="900">
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Manage refund </span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-0">
            <v-col cols="12" md="12">
            <p class=" h6"> The total amount paid on this invoice is {{ scoreDetail.currency_symbol }} {{ cart_item.amount }}</p>
            </v-col>
            <v-col cols="12" md="6">
              <v-select @change="changeRefundType()" :items="refund_refund_types" item-value="value" item-text="text" label="Refund Type" dense outlined v-model="refund.refund_type"></v-select>
            </v-col>
            <v-col cols="12" md="6" v-if="refund.refund_type=='partial'">
              <v-text-field @input="convertPercentage" @blur="checkPercentage()" label="Refund Percentage" dense outlined v-model="refund.refund_percentage" :error="$v.refund.refund_percentage.$error"></v-text-field>
              <span class="text-danger" v-if="$v.refund.refund_percentage.$error">This information is required</span>

            </v-col>
            <v-col cols="12" md="6">
              <v-text-field label="Refund Amount" dense outlined v-model="refund.refund_amount"></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea label="Remarks for refund" :error="$v.refund.refund_remarks.$error" v-model="refund.refund_remarks" dense outlined></v-textarea>
              <span class="text-danger" v-if="$v.refund.refund_remarks.$error">This information is required</span>
            </v-col>
            <v-col cols="12" md="12">
              <v-checkbox v-model="refund.cancel_enrolment_key">
                <template v-slot:label>
                  <div>
                   Cancel This Enrolment key After Refund

                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="cancel-btn" text x-large @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn class="btn btn-primary" dark x-large
               @click="createOrUpdate()"
               :loading="loading">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import SupportTicketService from "@/services/support/support-ticket/SupportTicketService";
import CartItemService from "@/services/cart/cartItem/CartItemService";
import AccountHolderService from "@/services/user/accountholder/AccountholderService";
import ScoreService from "@/services/score/score/ScoreService";
import {required, requiredIf} from "vuelidate/lib/validators";


const score=new ScoreService();
const accountHolder=new AccountHolderService();
const cartItem=new CartItemService();
const supportTicket=new SupportTicketService();
export default {
  validations:{
    refund:{
      refund_type:{required},
      refund_remarks:{required},
      refund_percentage:{
        required: requiredIf(function (nestedModel) {
          return this.refund.refund_type=='partial';
        })
      },
    }
  },
  data(){
    return{
      dialog:false,
      loading:false,
      ticket:{
        is_approved:false,
      },
      refund_refund_types:[
        {text:'Full',value:'full'},
        {text:'Partial',value:'partial'},
      ],
      refund:{
        refund_type:'full',
        refund_amount:0,
        refund_remarks:'',
        refund_percentage:'',
        cancel_enrolment_key:true,
      },
      cart_item:{},
      accountHolderDetail:{},
      scoreId:'',
      scoreDetail:{},
    }
  },
  methods:{
    manageRefund(item){
      this.ticket=item;
      this.dialog=true;
      if(item.cart_id){
        this.findCartItemDetail(item);
      }
      if(item.user_id){
        this.getAccountHolderDetail(item.user_id)
      }
    },
    //AccountHolder
    getAccountHolderDetail(userId){
      accountHolder
          .show(userId)
          .then((response) => {
            this.accountHolderDetail=response.data.accountHolder;
            if(this.accountHolderDetail){
              this.scoreId=this.accountHolderDetail.current_score_id;
              this.findScoreDetail();
            }
          })
          .catch(err => {

          }).finally(() => {

      });
    },
    //Score
    findScoreDetail(){
      if(this.scoreId){
        score
            .show(this.scoreId)
            .then((response) => {
              this.scoreDetail=response.data.score;
            })
            .catch(err => {

            }).finally(() => {

        });
      }
    },
    findCartItemDetail(item){
      let data={'candidate_id':item.candidate_id};
      cartItem
          .getItemByCandidate(item.cart_id,data)
          .then(response =>{
           this.cart_item=response.data.cart_item;
           this.refund.refund_amount=this.cart_item.amount;
          })
    },
    closeDialog(){
      this.dialog=false;
      this.ticket={
        is_approved:false,
      };
      this.refund={
          refund_type:'full',
          refund_amount:0,
          refund_remarks:'',
          refund_percentage:'',
        cancel_enrolment_key:true,
      };


      this.scoreDetail={};
      this.accountHolderDetail={};
      this.cart_item={};
      this.scoreId='';
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        supportTicket
            .manageRefund(this.ticket.id, this.refund)
            .then(response => {
              this.$snotify.success("Support Request Ticket has been Refunded");
              this.closeDialog();
              this.$emit('refresh');
            }).catch((err) => {

        })
            .finally(() => {
              this.loading = false
            })
      }

    },
    changeRefundType(){
      if(this.refund.refund_type=='full'){
        this.refund.refund_amount=this.cart_item.amount;
        this.refund.refund_percentage='';
        this.refund.cancel_enrolment_key=true;
      }
      if(this.refund.refund_type=='partial'){
        this.refund.cancel_enrolment_key=false;
      }

    },
    convertPercentage(){
      if(this.refund.refund_type=='partial'){
        if(this.refund.refund_percentage && this.refund.refund_percentage <=100){
          let  amount=(this.refund.refund_percentage/100)*this.cart_item.amount ;
          this.refund.refund_amount=amount.toFixed(2);
        }else{
          this.$snotify.error("Percentage cannot be more than 100");
        }
      }
    },
    checkPercentage(){
      if(this.refund.refund_percentage && this.refund.refund_percentage >100){
        this.refund.refund_percentage=100;
      }
    },
  },
  mounted() {

  }
}
</script>