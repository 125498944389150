<template>
  <v-dialog
      v-model="dialog"
      max-width="800"

  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Reassign ticket</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-select
                  v-model="support_ticket.assigned_type"
                  :items="types"
                  item-text="name"
                  item-value="value"
                  @change="getAssignToUsers"
                  :error="$v.support_ticket.assigned_type.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Access type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.support_ticket.assigned_type.$error">This information is required</span>
            </v-col>

<!--            <v-col cols="12" sm="12">-->
<!--              <v-combobox-->
<!--                  v-model="selectedUser"-->
<!--                  :items="adminUsers"-->
<!--                  item-text="full_name"-->
<!--                  item-value="id"-->
<!--                  @change="getSelectUser"-->
<!--                  outlined-->
<!--                  dense-->
<!--                  clearable-->
<!--              >-->
<!--                <template v-slot:label>-->
<!--                    {{support_ticket.assigned_type == 'state' ? `Select user for ${score}` : 'Select user for federal '}}-->
<!--                </template>-->
<!--              </v-combobox>-->
<!--            </v-col>-->

          </v-row>
        </v-container>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            x-large
            class="cancel-btn"
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
           dark
           x-large
            @click="createOrUpdate()"
            :loading="loading"
        >
          Assign
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import SupportTicketService from "@/services/support/support-ticket/SupportTicketService";
import AdminUserService from "@/services/admin-user/AdminUserService";

const supportTicket = new SupportTicketService();
const adminUser = new AdminUserService();
export default {
  name: "AssignedTo",
  data() {
    return {
      dialog: false,
      loading: false,
      selectedUser: null,
      score: null,
      adminUsers: [],
      currentUser: {},
      support_ticket: {
        'id': '',
        'score_id': '',
        'assigned_type': '',
        'assigned_to': '',
      },
      score_id: null,
      types: [
        { name: "Federal", value: "superadmin" },
        { name: "Score", value: "state" },
      ]
    }
  },
  validations: {
    support_ticket: {
      assigned_type: {required},
      assigned_by: {required},
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    getAssignToUsers() {
      this.selectedUser = null;
      if(this.support_ticket.assigned_type == 'state') {
        this.score_id = this.support_ticket.score_id;
      }else {
        this.score_id = null;
      }
      adminUser
          .getAllAdminUser(this.support_ticket.assigned_type, this.score_id)
          .then((response) => {
            this.adminUsers =response.data.adminUsers
          })
          .catch(err => {})
          .finally(() => {
            this.isLoading = false
          })
    },
    assignedTo(supportTicket) {
      this.dialog = true;
      this.getCurrentUser();
      this.score = supportTicket.score ? supportTicket.score.toLowerCase() : '';
      this.support_ticket.id = supportTicket.id;
      this.support_ticket.assigned_by = this.currentUser.id;
      this.support_ticket.assigned_type = 'superadmin';
      this.support_ticket.score_id = supportTicket.score_id;
      this.support_ticket.is_active = true;
      if (this.support_ticket.assigned_type != 'superadmin' && supportTicket.score_id != null) {
        this.score_id = supportTicket.score_id;
      }
      if(this.currentUser.access_type == 'score') {
        this.support_ticket.assigned_type = 'state';
      }
      this.getAssignToUsers();
    },
    getSelectUser() {
      if(this.selectedUser == null) {
        this.support_ticket.assigned_to = '';
      }else  {
        this.support_ticket.assigned_to = this.selectedUser.id;
      }
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        supportTicket
            .assignedTicket(this.support_ticket.id, this.support_ticket)
            .then(response => {
              this.$snotify.success("Ticket reassigned");
              this.closeDialog();
              this.resetForm();
              this.loading = false;
              this.$emit('refresh');
            })
            .catch((err) => {})
            .finally(() => this.loading = false );
      }
    },
    resetForm() {
      this.$v.$reset();
      this.selectedUser = null;
      this.score = null;
      this.support_ticket = {
        assigned_type: 'superadmin',
        assigned_by: '',
        assigned_to: ''
      }
    }
  }
}
</script>

<style scoped>

</style>