<template>
  <v-dialog v-model="dialog"
            max-width="600"
            scrollable>
    <v-card>
      <v-toolbar dark>
          <v-card-title>
            <span>Approved/Not approve refund</span>
            <hr>
          </v-card-title>
        </v-toolbar>
      <v-card-text>
        <v-container>
          <v-col cols="12" md="12">
            <label>Refund request has been reviewed with the following result:</label>
            <v-switch v-model="refund.is_approved" flat :label="refund.is_approved ? 'Approved' : 'Not approved'">
            </v-switch>
            <p>Add internal notes relating to the response. If the refund request has been approved, please note the amount to approve.</p>
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea v-model="refund.approved_remarks" label="Refund approval notes" dense outlined></v-textarea>
            <p>Once submitted, approved refund requests will be forwarded to Finance for processing,
              and requests not approved will be closed with notification sent to the Enroller.</p>
          </v-col>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn" text x-large @click="closeDialog">
          Cancel
        </v-btn>

        <v-btn class="btn btn-primary" dark x-large @click="createOrUpdate()" :loading="loading">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import SupportTicketService from "@/services/support/support-ticket/SupportTicketService";
const supportTicket=new SupportTicketService();
export default {
  data(){
    return{
      dialog:false,
      refund:{
        ticket_id:'',
        is_approved:false,
        approved_remarks:'',
      },
      loading:false,
    }
  },
  methods:{
    openDialog(ticketId,refundStatus){
      this.dialog=true;
      this.refund.ticket_id=ticketId;
      this.refund.is_approved=refundStatus;
    },
    closeDialog(){
      this.dialog=false;
      this.refund={
        ticket_id:'',
        is_approved:false,
      }
    },
    createOrUpdate(){
      this.loading=true;
      supportTicket
          .approvedRefund(this.refund.ticket_id,this.refund)
          .then(response => {
            this.$snotify.success(response.data.message);
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {

          }).finally(() => {
              this.loading=false;
          })
    },

  }
}
</script>